const initAccordions = (accordion) => {
	let id
	for (id = 0; id < accordion.length; id++) {
		accordion[id].addEventListener('click', function () {
			this.classList.toggle('active')

			if (this.classList.contains('active')) {
				this.lastElementChild.textContent = '-'
			} else {
				this.lastElementChild.textContent = '+'
			}

			let panel = this.nextElementSibling
			if (panel.style.display === 'block') {
				panel.style.display = 'none'
			} else {
				panel.style.display = 'block'
			}
		})
	}
}

window.addEventListener('load', async () => {
	const accordion = document.getElementsByClassName('cera-accordion-collapse')
	if (accordion) {
		initAccordions(accordion)
	}
})

window.addEventListener('arts/barba/transition/end', async () => {
	const accordion = document.getElementsByClassName('cera-accordion-collapse')
	if (accordion) {
		initAccordions(accordion)
	}
})
