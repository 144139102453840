const queryOptions = {
	service: 'service',
	package: 'package',
}
const serviceOptions = {
	not_sure: 'Not sure',
	branding: 'Brand identity',
	website: 'Website',
	webshop: 'Webshop',
	assistance: 'Assistance',
}
// packageOptions
const noOption = {}
const basicOptions = {
	standard: 'Standard',
	business: 'Business',
}

const packageOptions = {
	not_sure: noOption,
	branding: basicOptions,
	website: { ...basicOptions, premium: 'Premium' },
	webshop: { ...basicOptions, premium: 'Premium' },
	assistance: noOption,
}

const SERVICE_SELECTOR = 'service'
const PACKAGE_SELECTOR = 'package'

function reloadPackageSelection(service) {
	const options = packageOptions[service]
	const packageField = document.getElementById(PACKAGE_SELECTOR)
	// Clean & rebuild package options
	packageField.options.length = 0
	for (const [value, text] of Object.entries(options)) {
		packageField.options.add(new Option(text, value))
	}
	// Hide the package selector if there is no option
	packageField.parentElement.parentElement.style.display = packageField.options.length == 0 ? 'none' : ''
}

function objectHasOwn(obj, prop) {
	return Object.getOwnPropertyNames(obj).includes(prop)
}

function populateQueryParams(queryStr) {
	const queryParams = queryStr.split('&').reduce(function (result, item) {
		const [param, value] = item.split('=')
		if (param && value) {
			return { ...result, [param]: value }
		}
	}, {})

	if (objectHasOwn(queryParams, queryOptions.service) && objectHasOwn(serviceOptions, queryParams.service)) {
		const serviceField = document.getElementById(SERVICE_SELECTOR)
		serviceField.value = queryParams.service

		reloadPackageSelection(queryParams.service)
		const options = packageOptions[queryParams.service]
		if (objectHasOwn(queryParams, queryOptions.package) && objectHasOwn(options, queryParams.package)) {
			const packageField = document.getElementById(PACKAGE_SELECTOR)
			packageField.value = queryParams.package
		}
	}
}

function initOrderPage(event) {
	const orderingServiceSelector = document.getElementById(SERVICE_SELECTOR)
	if (orderingServiceSelector) {
		orderingServiceSelector.addEventListener('change', (event) => {
			reloadPackageSelection(event.target.value)
		})
		const queryStr = event.target.location.search.substring(1)
		populateQueryParams(queryStr)
	}
}

window.addEventListener('load', async (event) => initOrderPage(event))

window.addEventListener('arts/barba/transition/end', async (event) => initOrderPage(event))
