/* eslint-disable no-undef */
const mainConfigFile = '/config/page-setup.json'
var particlesConfig = { '/': undefined }
const fullScreenEnabled = {
	fullScreen: {
		enable: true,
	},
}

const initConfig = async () => {
	await fetch(mainConfigFile)
		.then((res) => res.json())
		.then((data) => (particlesConfig = data))
	for (const id in particlesConfig) {
		const item = particlesConfig[id]
		const config = await downloadConfig(item.configPath, item.isFullscreen).then((config) => (item.config = config))
		item.config = config
	}
}

const downloadConfig = (configPath, isFullscreen) => {
	return fetch(configPath)
		.then((res) => res.json())
		.then((data) => {
			if (isFullscreen) {
				return { ...data, fullScreenEnabled }
			}
			return data
		})
}

const cleanUp = (settings) => {
	tsParticles
		.dom()
		.filter((container) => container.id == settings.id) // Filter to containers from another page
		.forEach((container) => container.destroy()) // Remove the unnecessary containers
}

const loadPageParticles = (settings) => {
	const containerId = settings.id
	const particleWrapper = document.getElementById(containerId)
	if (particleWrapper) {
		tsParticles.load(containerId, settings.config)
	} else {
		console.error('ParticleContainer cannot be found!')
	}
}

const getSettings = async (pathname) => {
	let settings = particlesConfig[pathname]
	if (!settings) {
		await initConfig()
		settings = particlesConfig[pathname]
	}

	if (settings && !settings.config) {
		const config = await downloadConfig(item.configPath, item.isFullscreen).then((config) => (item.config = config))
		settings.config = config
	}
	return settings
}

window.addEventListener('load', async (event) => {
	const url = new URL(event.target.location.href)
	const pathname = url.pathname

	if (Object.getOwnPropertyNames(particlesConfig).includes(pathname)) {
		const settings = await getSettings(url.pathname)
		loadPageParticles(settings)
	}
})

window.addEventListener('arts/barba/transition/end', async (event) => {
	const url = new URL(event.target.location.href)
	const pathname = url.pathname

	if (Object.getOwnPropertyNames(particlesConfig).includes(pathname)) {
		const settings = await getSettings(url.pathname)
		cleanUp(settings)
		loadPageParticles(settings)
	}
})
